<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-row class="mb-2">
          <b-col
            md="2"
            cols="auto"
          >
            <v-select
              v-model="alert_status"
              label="title"
              :options="alertStatuses"
              placeholder="Durum"

              :reduce="alertStatus => alertStatus.id"
            />
          </b-col>
          <b-col>
            <b-form-datepicker
              v-model="sdate"
              v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-col>
          <b-col>
            <b-form-datepicker
              v-model="edate"
              v-bind="{labelNoDateSelected: 'Başlangıç Tarihi',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
              locale="tr"
              start-weekday="1"
            />
          </b-col>
          <b-col cols="auto">
            <b-button
              to="alerts/search"
              variant="primary"
              block
            ><FeatherIcon icon="PlusIcon" />
              Oluştur</b-button>
          </b-col>
        </b-row>
        <b-card-text>
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
            fixed
          >
            <template #cell(customer)="data">
              <div>{{ data.item.customer }}</div>
              <div
                v-if="data.item.company_name"
                class="text-warning"
              >
                <small>{{ data.item.company_name }}</small>
              </div>
            </template>
            <template #cell(alert_date)="data">
              {{ data.item.alert_date? moment(data.item.alert_date).format('DD.MM.YYYY') : '-' }}
            </template>
            <template #cell(alert_status)="data">
              <div class="text-center">
                <b-badge
                  v-if="data.item.alert_status === '0'"
                  variant="warning"
                >
                  Beklemede
                </b-badge>
                <b-badge
                  v-if="data.item.alert_status === '1'"
                  variant="success"
                >
                  Tamamlandı
                </b-badge>
              </div>
            </template>
            <template #cell(control)="data">
              <b-dropdown
                :split-to="'/alerts/view/' + data.item.id"
                text="Görüntüle"
                variant="outline-primary"
                size="sm"
                split
              >
                <b-dropdown-item :to="'/alerts/edit/' + data.item.id">
                  Güncelle
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table></b-card-text>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="dataCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BTable, BCardFooter, BPagination, BButton, BFormDatepicker, BBadge, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardText,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    BFormDatepicker,
    BBadge,
    BDropdown,
    BDropdownItem,
    vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      currentPage: 1,
      perPage: 10,
      alert_status: '0',
      sdate: null,
      edate: null,
      alertStatuses: [
        {
          id: '0',
          title: 'Beklemede',
        },
        {
          id: '1',
          title: 'Tamamlandı',
        },
      ],
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        {
          key: 'alert_status',
          label: 'Durum',
          thStyle: { textAlign: 'center', width: '200px' },
        },
        {
          key: 'alert_date',
          label: 'Tarih',
          thStyle: { width: '200px' },
        },
        {
          key: 'control',
          label: 'Kontrol',
          thStyle: { width: '180px' },
        },
      ],
      dataQuery: {
        select: [
          'com_alert.id AS id',
          'com_alert.alert_date AS alert_date',
          'com_alert.alert_status AS alert_status',
          'com_alert.interview_number AS interview_number',
          'com_customer.name AS customer',
          'com_customer.company_name AS company_name',
        ],
        order_by: ['com_alert.alert_date', 'DESC'],
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['alerts/getAlerts']
    },
    dataCount() {
      return this.$store.getters['alerts/getAlertsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    alert_status() {
      this.getDataList()
    },
    sdate() {
      this.getDataList()
    },
    edate() {
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.dataQuery.where = {
        'com_alert.id_com_user': this.userData.id,
        // 'com_alert.id_com_user': 48,
      }
      if (this.alert_status !== null) {
        this.dataQuery.where['com_alert.alert_status'] = this.alert_status
      }
      if (this.sdate !== null) {
        this.dataQuery.where['com_alert.alert_date >='] = this.sdate
      }
      if (this.edate !== null) {
        this.dataQuery.where['com_alert.alert_date <='] = this.edate
      }
      this.$store.dispatch('alerts/alertsList', this.dataQuery)
    },
  },
}
</script>
